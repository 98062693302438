// @flow

/**
 * Module dependencies.
 */

import { color } from 'react-components/styles';
import { ifProp } from 'styled-tools';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {
  className?: string,
  isOpen: boolean,
  onClick?: () => void
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  --duration: 0.5s;
  --menu-button-color: ${color('green500')};

  cursor: pointer;
  height: 54px;
  outline: none;
  position: relative;
  width: 54px;

  > span {
    background-color: var(--menu-button-color);
    display: block;
    height: 2px;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%) translateY(-50%);
    transform-origin: 50% 50%;
    transition: var(--duration);
    transition-property: background-color, transform;
    width: 22px;

    &::before,
    &::after {
      background-color: var(--menu-button-color);
      content: '';
      display: block;
      height: 2px;
      position: absolute;
      transform: translateY(-6px);
      transition: var(--duration);
      transition-property: background-color, transform;
      width: 22px;
    }

    &::after {
      transform: translateY(6px);
    }
  }

  svg {
    fill: ${color.transparentize('black', 0.2)};
    stroke-dasharray: 20 230;
    stroke-width: 2px;
    stroke: transparent;
    transition: var(--duration);
    transition-property: stroke-dasharray, stroke-dashoffset, stroke;
  }

  &:focus,
  &:hover {
    svg {
      stroke: var(--menu-button-color);
    }
  }

  ${ifProp('isOpen', css`
    transform: rotate(360deg);

    > span {
      background: transparent;

      &::before {
        transform: translateY(0) rotate(45deg);
      }

      &::after {
        transform: translateY(0) rotate(-45deg);
      }
    }

    svg {
      stroke-dasharray: 160;
      stroke-dashoffset: -65;
      stroke: ${color('green300')};
    }
  `, css`
    svg {
      stroke-dashoffset: 0;
    }
  `)}
`;

/**
 * `Menu` component.
 */

function Menu({ className, isOpen, onClick }: Props): Node {
  return (
    <Wrapper
      aria-label={'Close'}
      className={className}
      isOpen={isOpen}
      onClick={onClick}
      role={'button'}
    >
      <span />

      <svg
        aria-hidden
        height={'54px'}
        viewBox={'0 0 54 54'}
        width={'54px'}
        x={'0'}
        y={'0'}
      >
        <path d={'M16.500,27.000 C16.500,27.000 24.939,27.000 38.500,27.000 C52.061,27.000 49.945,15.648 46.510,11.367 C41.928,5.656 34.891,2.000 27.000,2.000 C13.193,2.000 2.000,13.193 2.000,27.000 C2.000,40.807 13.193,52.000 27.000,52.000 C40.807,52.000 52.000,40.807 52.000,27.000 C52.000,13.000 40.837,2.000 27.000,2.000 '} />
      </svg>
    </Wrapper>
  );
}

/**
 * Export `Menu` component.
 */

export default Menu;
