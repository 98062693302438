// @flow

/**
 * Module dependencies.
 */

import { BackgroundImage } from 'components/core/image';
import { Button } from 'components/core/buttons';
import { Icon } from 'react-components/media';
import { Type } from 'react-components/typography';
import { color, media, states, units } from 'react-components/styles';
import { theme } from 'styled-tools';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import closeIcon from 'assets/svg/close.svg';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel: string,
  buttonUrl: string,
  image: Object,
  onHide: () => void,
  text: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.aside`
  background-color: #adc1f9;
  display: grid;
  grid-template-columns: 1fr;
  left: 0;
  min-height: ${units(8)};
  position: sticky;
  right: 0;
  top: 0;
  z-index: ${theme('zIndex.announcementBar')};

  ${media.min('md')`
    grid-template-columns: 3fr 9fr;
  `}
`;

/**
 * `ImageWrapper` styled component.
 */

const ImageWrapper = styled.div`
  overflow: hidden;
  position: relative;

  &::before {
    background-color: ${color('grey100')};
    content: '';
    height: 15vw;
    position: absolute;
    right: 0;
    top: 0;
    transform: rotate(10deg);
    transform-origin: top right;
    width: 100%;
  }

  ${media.max('md')`
    display: none;
  `};
`;

/**
 * `Content` styled component.
 */

const Content = styled.div`
  align-items: start;
  display: grid;
  grid-gap: 0.5rem;
  grid-template-areas:
    'text   close'
    'button button';
  grid-template-columns: 1fr max-content;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 1.2rem;
  padding-top: 0.5rem;

  ${media.min('ms')`
    align-items: center;
    grid-gap: 1rem;
    grid-template-areas: 'text button close';
    grid-template-columns: 1fr max-content max-content;
    padding-left: 2rem;
  `}

  ${media.min('md')`
    padding-left: 3.15rem;
  `}
`;

/**
 * `StyledButton` styled component.
 */

const StyledButton = styled(Button)`
  grid-area: button;
  justify-self: center;
`;

/**
 * `CloseButton` styled component.
 */

const CloseButton = styled.button.attrs({ type: 'button' })`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: ${color('blue800')};
  cursor: pointer;
  display: flex;
  grid-area: close;
  height: ${units(4)};
  justify-content: center;
  padding: 0;
  transition: color ${theme('transitions.fast')};
  width: ${units(4)};

  ${states.interaction`
    color: ${color('blue500')};
  `}
`;

/**
 * `AnnouncementBar` component.
 */

function AnnouncementBar(props: Props): Node {
  const { buttonLabel, buttonUrl, image, onHide, text } = props;

  return (
    <Wrapper>
      <ImageWrapper aria-hidden>
        <BackgroundImage fluid={image.file.childImageSharp.fluid} />
      </ImageWrapper>

      <Content>
        <Type.H5
          as={'div'}
          color={color('blue800')}
          gridArea={'text'}
        >
          <RawHtml>
            {text}
          </RawHtml>
        </Type.H5>

        <StyledButton
          colorTheme={'secondary'}
          href={buttonUrl}
          size={'small'}
          target={'_blank'}
          variant={'outline'}
        >
          {buttonLabel}
        </StyledButton>

        <CloseButton
          aria-label={'Don\'t show again'}
          onClick={onHide}
        >
          <Icon
            aria-hidden
            icon={closeIcon}
            size={'12px'}
          />
        </CloseButton>
      </Content>
    </Wrapper>
  );
}

/**
 * Export `AnnouncementBar` component.
 */

export default AnnouncementBar;
