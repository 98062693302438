// @flow

/**
 * Module dependencies.
 */

import { color, media, units } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `FormGroup` styled component.
 */

export const FormGroup = styled.div`
  margin-bottom: 2rem;
  position: relative;

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.55;
    pointer-events: none;
  `)}

  * {
    outline: none;
  }
`;

/**
 * Export `Label` styled component.
 */

export const Label = styled.label`
  ${theme('typography.styles.h5')}

  border: 0;
  color: ${color('blue350')};
  left: 1.5rem;
  overflow: hidden;
  pointer-events: none;
  position: absolute;
  right: 1.5rem;
  text-overflow: ellipsis;
  top: 14px;
  transform: translateY(0);
  transition: ${theme('transitions.default')};
  transition-property: font-size, transform; /* stylelint-disable-line plugin/no-low-performance-animation-properties */
  white-space: nowrap;

  ${ifProp('isFloating', css`
    transform: translateY(-12px);

    ${media.max('lg')`
      font-size: 11px;
    `}

    ${media.min('lg')`
      font-size: 11px;
    `}
  `)}
`;

/**
 * Export `FormControlStyle` styled component.
 */

export const FormControlStyle = styled.div`
  background-color: transparent;
  border: 1px solid ${color('blue500')};
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  transition: background-color ${theme('transitions.default')};

  ${ifProp('hasFocus', css`
    background-color: ${color('blue700')};
  `)}
`;

/**
 * Export `FormControl` styled component.
 */

export const FormControl = styled.input`
  ${theme('typography.styles.h5')}

  background: none;
  border: 0;
  box-shadow: none;
  color: ${color('white')};
  display: block;
  height: 3.5rem;
  padding: 1.3rem 1.5rem 0.5rem;
  width: 100%;
`;

/**
 * Export `ErrorMessage` styled component.
 */

export const ErrorMessage = styled.div`
  ${theme('typography.styles.small')}

  color: ${color('error400')};
  left: 1rem;
  line-height: 1rem;
  opacity: ${ifProp('visible', 1, 0)};
  overflow: hidden;
  pointer-events: ${ifProp('visible', 'all', 'none')};
  position: absolute;
  right: ${units(2)};
  text-overflow: ellipsis;
  top: calc(100% + 0.3rem);
  transition: opacity ${theme('transitions.default')};
  white-space: nowrap;
`;
