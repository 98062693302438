// @flow

/**
 * Module dependencies.
 */

import { color } from 'react-components/styles';
import { switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * `Badge` styled component.
 */

const Badge = styled.div`
  ${theme('typography.styles.label')}

  align-items: center;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  letter-spacing: normal;
  padding: 0 0.5rem 1px;

  ${switchProp('colorTheme', {
    blueFill: css`
      background-color: ${color('blue400')};
      color: ${color('white')};
    `,
    darkBlueOutline: css`
      background-color: ${color('white')};
      border: 1px solid ${color('blue700')};
      color: ${color('blue700')};
    `,
    greenFill: css`
      background-color: ${color('green400')};
      color: ${color('white')};
    `,
    greenOutline: css`
      background-color: ${color('white')};
      border: 1px solid ${color('green500')};
      color: ${color('green500')};
    `,
    greyFill: css`
      background-color: ${color('grey500')};
      color: ${color('white')};
    `
  })}
`;

/**
 * Default props.
 */

Badge.defaultProps = {
  colorTheme: 'blueFill'
};

/**
 * Export `Badge` component.
 */

export default Badge;
