// @flow

/**
 * Module dependencies.
 */

import { FormProvider } from '@seegno/react-forms';
import React, { type Node } from 'react';

/**
 * `Props` type.
 */

type Props = {|
  children: Node,
  className?: string,
  initialValues?: Object,
  jsonSchema: Object,
  onSubmit: (values: Object, actions: Object) => ?Promise<any>,
  stateReducer?: (state: Object, action: Object) => Object
|};

/**
 * Export `Form` component.
 */

export function Form(props: Props): Node {
  const {
    children,
    initialValues,
    jsonSchema,
    onSubmit,
    stateReducer,
    ...rest
  } = props;

  return (
    <FormProvider
      initialValues={initialValues}
      jsonSchema={jsonSchema}
      onSubmit={onSubmit}
      stateReducer={stateReducer}
    >
      {({ submit }) => (
        <form
          {...rest}
          onSubmit={submit}
        >
          {children}
        </form>
      )}
    </FormProvider>
  );
}
