
// @flow

/**
 * Module dependencies.
 */

import { GlobalStyles } from 'styles/global';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import AnnouncementBar from 'components/announcement-bar';
import React, { type Node, useEffect, useState } from 'react';
import firebase from 'gatsby-plugin-firebase';

/**
 * `Props` type.
 */

type Props = {
  children: Node
};

/**
 * Query.
 */

const query = graphql`
  query {
    config {
      firebase {
        apiKey
        appId
        authDomain
        databaseURL
        measurementId
        messagingSenderId
        projectId
        storageBucket
      }
    }

    stickyBars: allSeegnoCmsStickyBarEntity(
      filter: { content: { visible: { eq: true } } },
      limit: 1
    ) {
      nodes {
        id
        content {
          buttonLabel
          buttonUrl
          image {
            file {
              childImageSharp {
                ...ResponsiveImage
              }
            }
          }
          text
        }
      }
    }
  }
`;

/**
 * `PageContainer` component.
 */

function PageContainer({ children }: Props): Node {
  const location = useLocation();
  const { config, stickyBars } = useStaticQuery(query);
  const stickyBar = stickyBars?.nodes?.[0];
  const [isStickyBarVisible, setStickyBarVisible] = useState(false);

  useEffect(() => {
    if (stickyBar && localStorage.getItem(`announcement_${stickyBar.id}`) !== '1') {
      setStickyBarVisible(true);
    }
  }, [stickyBar]);

  useEffect(() => {
    const isFirebaseEnabled = Object.values(config.firebase).some(Boolean);

    if (!isFirebaseEnabled || !firebase?.analytics) {
      return;
    }

    const pagePath = location.pathname;

    firebase.analytics().setCurrentScreen(pagePath);
    firebase.analytics().logEvent('page_view', { page_path: pagePath });
  }, [config, location]);

  return (
    <>
      <GlobalStyles />

      {isStickyBarVisible && (
        <AnnouncementBar
          {...stickyBar.content}
          onHide={() => {
            setStickyBarVisible(false);
            localStorage.setItem(`announcement_${stickyBar.id}`, '1');
          }}
        />
      )}

      {children}
    </>
  );
}

/**
 * Export `PageContainer` component.
 */

export default PageContainer;
