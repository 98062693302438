// @flow

/**
 * Module dependencies.
 */

import { Type } from 'react-components/typography';
import { color, units } from 'react-components/styles';
import { ifProp, theme } from 'styled-tools';
import { useField, useFormState } from '@seegno/react-forms';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import styled, { css } from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  className?: string,
  disabled?: boolean,
  label: string,
  name: string
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled(Type.Small)`
  align-items: baseline;
  color: ${color('grey500')};
  display: grid;
  grid-gap: ${units(1.5)};
  grid-template-columns: max-content 1fr;

  ${ifProp('hasError', css`
    animation: ${theme('keyframes.shake')} ${theme('transitions.default')};
    color: ${color('error400')};
  `)}
`;

/**
 * `Input` styled component.
 */

const Input = styled.input`
  position: relative;
  top: 2px;
`;

/**
 * `Checkbox` component.
 */

function Checkbox(props: Props): Node {
  const { className, disabled, label, name, ...rest } = props;
  const { error, meta, onChange, value, ...fieldProps } = useField(name);
  const { isSubmitting } = useFormState();

  return (
    <Wrapper
      as={'label'}
      className={className}
      hasError={!isSubmitting && !!error && meta.touched}
    >
      <Input
        {...fieldProps}
        {...rest}
        checked={!!value}
        disabled={disabled || isSubmitting}
        name={name}
        onChange={event => onChange(event.target.checked)}
        type={'checkbox'}
      />

      <RawHtml>
        {label}
      </RawHtml>
    </Wrapper>
  );
}

/**
 * Export `Checkbox` component.
 */

export default Checkbox;
