// @flow

/**
 * Module dependencies.
 */

import { prop, theme } from 'styled-tools';
import { units } from 'react-components/styles';
import styled from 'styled-components';

/**
 * Export `Loader` styled component.
 */

export const Loader = styled.span.attrs(({ colorTheme, size, stroke }) => ({
  colorTheme: colorTheme ?? 'currentColor',
  size: size ?? units(3),
  stroke: stroke ?? '2px'
}))`
  animation: ${theme('keyframes.spin')} 0.75s linear infinite;
  border-bottom-color: ${prop('colorTheme')};
  border-left-color: transparent;
  border-radius: ${prop('size')};
  border-right-color: ${prop('colorTheme')};
  border-style: solid;
  border-top-color: ${prop('colorTheme')};
  border-width: ${prop('stroke')};
  display: block;
  height: ${prop('size')};
  width: ${prop('size')};
`;
