// @flow

/**
 * Module dependencies.
 */

import { get, isEmpty, template } from 'lodash';
import { useCallback } from 'react';
import translations from 'locales/en/translation.json';

/**
 * Export `useTranslate` hook.
 */

export function useTranslate() {
  const translate = useCallback((key: string, interpolations?: Object) => {
    const translation = get(translations, key);

    if (isEmpty(interpolations)) {
      return translation;
    }

    return template(translation, { interpolate: /{{([\s\S]+?)}}/g })(interpolations);
  }, []);

  return translate;
}
