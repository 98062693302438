// @flow

/**
 * Module dependencies.
 */

import { Button } from 'components/core/buttons';
import {
  ErrorMessage,
  FormControl,
  FormControlStyle,
  FormGroup,
  Label
} from 'components/core/forms/fields-styled-components';

import { Loader } from 'components/core/loader';
import { isEmpty, trim } from 'lodash';
import { media } from 'react-components/styles';
import { useErrorMessage } from 'hooks/use-error-message';
import { useField, useFormState } from '@seegno/react-forms';
import React, { type Node, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

/**
 * `Props` type.
 */

type Props = {|
  buttonLabel: string,
  buttonType: 'button' | 'submit',
  className?: string,
  disabled?: boolean,
  id?: string,
  label: string,
  name: string,
  onFocus?: SyntheticEvent<HTMLInputElement> => void,
  type?: string
|};

/**
 * `StyledFormGroup` styled component.
 */

const StyledFormGroup = styled(FormGroup)`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(2, 1fr);

  ${media.min('sm')`
    grid-template-columns: 1fr minmax(auto, 14rem);
    grid-template-rows: 1fr;
  `}
`;

/**
 * `StyledFormControlStyle` styled component.
 */

const StyledFormControlStyle = styled(FormControlStyle)`
  grid-column: 1;
  grid-row: 1 / -1;

  ${media.min('sm')`
    grid-column: 1 / -1;
    grid-row: 1;
  `}
`;

/**
 * `FormControlWrapper` styled component.
 */

const FormControlWrapper = styled.div`
  grid-column: 1;
  grid-row: 1 / -2;
  position: relative;

  ${media.min('sm')`
    grid-column: 1 / -2;
    grid-row: 1;
  `}
`;

/**
 * `ButtonWrapper` styled component.
 */

const ButtonWrapper = styled.div`
  grid-column: 1;
  grid-row: 2 / -1;

  ${media.min('sm')`
    grid-column: 2 / -1;
    grid-row: 1;
  `}
`;

/**
 * Export `JoinWaitlistInput` component.
 */

export function JoinWaitlistInput(props: Props): Node {
  const {
    buttonLabel = 'button',
    buttonType,
    className,
    disabled,
    id,
    label,
    name,
    onFocus,
    ...rest
  } = props;

  const {
    error,
    meta,
    onBlur,
    onChange,
    onFocus: onFieldFocus,
    value,
    ...fieldProps
  } = useField(name);

  const { active, touched } = meta || {};
  const [hasRequiredError, setRequiredError] = useState(false);
  const { isSubmitting } = useFormState();
  const errorMessage = useErrorMessage(error);
  const hasError = hasRequiredError || error?.rule !== 'required' && touched;
  const hasValue = Boolean(value) || active;
  const isDisabled = disabled || isSubmitting;
  const handleChange = useCallback(event => {
    const value = event.target.value;

    onChange(!isEmpty(value) ? trim(value) : undefined);
  }, [onChange]);

  const handleBlur = useCallback(event => {
    const value = event.target.value;

    onChange(!isEmpty(value) ? trim(value) : undefined);
    onBlur(event);
  }, [onBlur, onChange]);

  useEffect(() => {
    if (!!error && isSubmitting) {
      setRequiredError(true);
    }
  }, [error, isSubmitting]);

  useEffect(() => {
    if (!error && value) {
      setRequiredError(false);
    }
  }, [error, value]);

  return (
    <StyledFormGroup
      className={className}
      disabled={isDisabled}
    >
      <StyledFormControlStyle hasFocus={hasValue} />

      <FormControlWrapper>
        <Label
          htmlFor={id ?? name}
          isFloating={hasValue}
        >
          {label}
        </Label>

        <FormControl
          disabled={isDisabled}
          id={id ?? name}
          name={name}
          onBlur={handleBlur}
          onChange={handleChange}
          onFocus={event => {
            if (onFocus) {
              onFocus(event);
            }

            onFieldFocus(event);
          }}
          value={value || ''}
          {...fieldProps}
          {...rest}
        />
      </FormControlWrapper>

      <ErrorMessage visible={hasError}>
        {hasError ? errorMessage : null}
      </ErrorMessage>

      <ButtonWrapper>
        <Button
          colorTheme={'primary'}
          disabled={disabled}
          fullWidth
          type={buttonType}
          variant={'fill'}
        >
          {isSubmitting ? <Loader /> : buttonLabel}
        </Button>
      </ButtonWrapper>
    </StyledFormGroup>
  );
}
