// @flow

/**
 * Module dependencies.
 */

import { Container } from 'components/core/layout';
import { Icon } from 'react-components/media';
import { Image } from 'components/core/image';
import { Type } from 'react-components/typography';
import { color, media, themeProp } from 'react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { ifProp, prop, theme } from 'styled-tools';
import { map } from 'lodash';
import { useSocialNetworks } from 'hooks/use-social-networks';
import Badge from 'components/core/badge';
import Link from 'components/core/links/link';
import RawHtml from 'react-components/raw-html';
import React, { type Node } from 'react';
import discordIcon from 'assets/svg/social/discord.svg';
import linkedinIcon from 'assets/svg/social/linkedin.svg';
import logoSvg from 'assets/svg/logo-type.svg';
import mediumIcon from 'assets/svg/social/medium.svg';
import styled from 'styled-components';
import twitterIcon from 'assets/svg/social/twitter.svg';
import youtubeIcon from 'assets/svg/social/youtube.svg';

/**
 * `LinksListProps` type.
 */

type LinksListProps = {|
  gridArea?: string,
  items: Array<Object>,
  title: string
|};

/**
 * Icons list.
 */

const iconsList = {
  discord: discordIcon,
  linkedin: linkedinIcon,
  medium: mediumIcon,
  twitter: twitterIcon,
  youtube: youtubeIcon
};

/**
 * Footer query.
 */

const footerQuery = graphql`
  query {
    settings: allSeegnoCmsSetting {
      nodes {
        code
        description
        value
      }
    }

    footer: seegnoCmsFooterEntityEntity {
      content {
        address
        title
        lists {
          id
          key
          title
          links {
            badge
            disabled
            id
            label
            url
          }
        }
        trademark
      }
    }

    bubblesLeft: file(relativePath: { eq: "images/bubbles-left.png" }) {
      childImageSharp {
        fluid(maxWidth: 394, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    bubblesRight: file(relativePath: { eq: "images/bubbles-right.png" }) {
      childImageSharp {
        fluid(maxWidth: 394, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

/**
 * Current year.
 */

const currentYear = new Date().getFullYear();

/**
 * `BubblesLeft` styled component.
 */

const BubblesLeft = styled.div`
  left: 0;
  position: absolute;
  top: 5rem;
  transform: translateX(-65%);
  width: 194px;

  ${media.min('sm')`
    bottom: 0;
    top: auto;
    transform: translateX(-45%);
  `}

  ${media.min('lg')`
    bottom: auto;
    top: 0;
  `}
`;

/**
 * `BubblesRight` styled component.
 */

const BubblesRight = styled.div`
  bottom: 12.5rem;
  position: absolute;
  right: 0;
  transform: translateX(35%);
  width: 194px;

  ${media.min('lg')`
    bottom: 1rem;
    transform: translateX(25%);
  `}
`;

/**
 * `FooterWrapper` styled component.
 */

const FooterWrapper = styled.div`
  overflow: hidden;
`;

/**
 * `Grid` styled component.
 */

const Grid = styled.footer`
  display: grid;
  grid-gap: 2rem;
  grid-template-areas: 'legal' 'apps' 'token' 'social' 'pmint' 'trademark';
  justify-content: center;
  padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;
  text-align: center;

  ${media.min('sm')`
    grid-template-areas:
      'legal     apps'
      'token     social'
      'pmint     pmint'
      'trademark trademark';
    grid-template-columns: repeat(2, 1fr);
  `}

  ${media.min('ms')`
    grid-template-areas:
      'legal     apps      token     social'
      '.         pmint     pmint     .'
      'trademark trademark trademark trademark';
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    padding-bottom: 2.5rem;
    padding-top: 2.5rem;
  `}

  ${media.min('lg')`
    grid-gap: 0;
    grid-template-areas:
      'pmint     legal     apps      token     social'
      'trademark trademark trademark trademark trademark';
    grid-template-columns: 3fr repeat(3, 2fr) 1fr;
    text-align: left;
  `}
`;

/**
 * `GridColumn` styled component.
 */

const GridColumn = styled.div`
  grid-area: ${prop('gridArea')};
`;

/**
 * `ListTitle` styled component.
 */

const ListTitle = styled(Type.Label)`
  color: ${color('blue500')};
  display: block;
  font-weight: 700;
  margin-bottom: 1rem;
  text-transform: uppercase;
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  align-items: center;
  display: inline-flex;
  font-weight: 700;
  margin-bottom: 0.5rem;

  svg {
    color: ${color('blue500')};
    transition: color ${themeProp('transitions.default')};
  }

  &:focus,
  &:hover {
    svg {
      color: ${color('green300')};
    }
  }
`;

/**
 * `StyledIcon` styled component.
 */

const StyledIcon = styled(Icon).attrs({ size: '1rem' })`
  margin-right: 0.75rem;
`;

/**
 * `StyledBadge` styled component.
 */

const StyledBadge = styled(Badge)`
  margin-left: 1rem;
  position: relative;
  top: -2px;
`;

/**
 * `PublicmintLogo` styled component.
 */

const PublicmintLogo = styled(Icon).attrs({
  icon: logoSvg,
  size: '126px'
})`
  margin-right: 0.75rem;

  ${media.max('lg')`
    max-width: 5.5rem;
  `}
`;

/**
 * `Pmint` styled component.
 */

const Pmint = styled.div`
  grid-area: pmint;
  padding-top: 2rem;

  ${media.min('xs')`
    padding-top: 0;
  `}

  ${media.min('lg')`
    max-width: 15rem;
  `}
`;

/**
 * `Address` styled component.
 */

const Address = styled.div`
  ${theme('typography.styles.small')}

  color: ${color('blue500')};
  font-weight: 600;
  padding-top: 1.25rem;
`;

/**
 * `Trademark` styled component.
 */

const Trademark = styled.div`
  align-items: center;
  display: grid;
  grid-area: trademark;
  grid-row-gap: 0.25rem;
  justify-content: center;

  ${media.min('md')`
    grid-auto-flow: column;
    grid-column-gap: 0.5rem;
  `}

  ${media.min('lg')`
    padding-top: 3.5rem;
  `}
`;

/**
 * `TrademarkText` styled component.
 */

const TrademarkText = styled(Type.Small)`
  color: ${color('blue500')};
  display: block;

  ${ifProp('bold', `
    font-weight: 700;
  `)}
`;

/**
 * `LinksList` component.
 */

function LinksList({ gridArea, items, title }: LinksListProps): Node {
  return items && (
    <GridColumn gridArea={gridArea}>
      <ListTitle>
        {title}
      </ListTitle>

      <ul>
        {items.map(({ badge, disabled, iconKey, label, url }) => (
          <li key={label}>
            <StyledLink
              disabled={disabled}
              href={url}
            >
              {iconsList?.[iconKey] && (
                <StyledIcon icon={iconsList?.[iconKey]} />
              )}

              <Type.Small fontWeight={700}>
                {label}
              </Type.Small>

              {badge && <StyledBadge>{badge}</StyledBadge>}
            </StyledLink>
          </li>
        ))}
      </ul>
    </GridColumn>
  );
}

/**
 * `Footer` component.
 */

function Footer(): Node {
  const data = useStaticQuery(footerQuery);
  const footerMenusLists = data.footer.content.lists;
  const socialNetworksLinks = useSocialNetworks();

  return (
    <FooterWrapper>
      <Container>
        <BubblesRight>
          <Image fluid={data.bubblesRight.childImageSharp.fluid} />
        </BubblesRight>

        <BubblesLeft>
          <Image fluid={data.bubblesLeft.childImageSharp.fluid} />
        </BubblesLeft>

        <Grid>
          {map(footerMenusLists, ({ key, links, title }) => (
            <LinksList
              gridArea={key}
              items={links}
              key={key}
              title={title}
            />
          ))}

          <LinksList
            gridArea={'social'}
            items={socialNetworksLinks}
            title={'Follow us'}
          />

          <Pmint>
            <Link href={'/'}>
              <PublicmintLogo aria-label={'Public Mint'} />
            </Link>

            <Address>
              <RawHtml>
                {data.footer.content.address}
              </RawHtml>
            </Address>
          </Pmint>

          <Trademark>
            <TrademarkText bold>
              {`© ${currentYear} Public Mint`}
            </TrademarkText>

            <TrademarkText>
              {data.footer.content.trademark}
            </TrademarkText>
          </Trademark>
        </Grid>
      </Container>
    </FooterWrapper>
  );
}

/**
 * Export `Footer` component.
 */

export default Footer;
