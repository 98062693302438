// @flow

/**
 * Module dependencies.
 */

import React, {
  type Node,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react';

import Recaptcha from 'react-google-recaptcha';
import styled from 'styled-components';

/**
 * `Options` type.
 */

type Options = {|
  onSubmit: (values: Object, actions: Object) => ?Promise<any>,
  recaptchaSiteKey: ?string
|};

/**
 * `RecaptchaResult` type.
 */

type RecaptchaResult = {|
  isRecaptchaExecuting: boolean,
  renderRecaptcha: () => Node,
  submitWithRecaptcha: (values: Object, actions: Object) => Promise<any>
|};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  position: relative;
  z-index: 9999;
`;

/**
 * Export `useRecaptcha`.
 */

export function useRecaptcha(options: Options): RecaptchaResult {
  const { onSubmit, recaptchaSiteKey } = options;
  const ref = useRef<Recaptcha | null>(null);
  const submitRef = useRef(onSubmit);
  const [isExecuting, setExecuting] = useState(false);

  useEffect(() => {
    submitRef.current = onSubmit;
  });

  const renderRecaptcha = useCallback(() => {
    if (!recaptchaSiteKey) {
      return null;
    }

    return (
      <Wrapper>
        <Recaptcha
          ref={ref}
          sitekey={recaptchaSiteKey}
          size={'invisible'}
        />
      </Wrapper>
    );
  }, [recaptchaSiteKey]);

  const submitWithRecaptcha = useCallback(async (values, actions) => {
    const recaptcha = ref.current;

    if (!recaptchaSiteKey || !recaptcha) {
      return onSubmit(values);
    }

    setExecuting(true);

    const recaptchaToken = await recaptcha.executeAsync();

    try {
      return await onSubmit(
        {
          ...values,
          'g-recaptcha-response': recaptchaToken
        },
        actions
      );
    } finally {
      setExecuting(false);
      recaptcha.reset();
    }
  }, [onSubmit, recaptchaSiteKey]);

  return {
    isRecaptchaExecuting: isExecuting,
    renderRecaptcha,
    submitWithRecaptcha
  };
}
