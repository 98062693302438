// @flow

/**
 * Module dependencies.
 */

import { breakpoints } from 'react-components/styles';
import { useMediaQuery } from './use-media-query';

/**
 * `Breakpoint` type.
 */

type Breakpoint = $Keys<typeof breakpoints>;

/**
 * `Type` type.
 */

type Type = 'min' | 'max';

/**
 * Get breakpoint value.
 */

function getBreakpointValue(breakpoint: Breakpoint, type: Type): number {
  const value = breakpoints[breakpoint];

  return type === 'max' ? value - 1 : value;
}

/**
 * `useBreakpoint` hook.
 */

function useBreakpoint(breakpoint: Breakpoint, type?: Type = 'min'): boolean {
  const breakpointValue = getBreakpointValue(breakpoint, type);
  const query = `(${type}-width: ${breakpointValue}px)`;

  return useMediaQuery(query);
}

/**
 * Export `useBreakpoint` hook.
 */

export default useBreakpoint;
