// @flow

/**
 * Module dependencies.
 */

import { useTranslate } from './use-translate';

/**
 * `Error` type.
 */

type Error = {
  args?: Object,
  rule: string
};

/**
 * Export `useErrorMessage` hook.
 */

export const useErrorMessage = (error: ?Error): ?string => {
  const translate = useTranslate();

  if (!error) {
    return null;
  }

  const errorMessage = translate(`form.errors.${error?.rule}`, error?.args);

  if (errorMessage) {
    return errorMessage;
  }

  return translate('form.errors.defaultError');
};
