// @flow

/**
 * Module dependencies.
 */

import { graphql } from 'gatsby';
import GatsbyBackgroundImage from 'gatsby-image';
import React, { type Node } from 'react';

/**
 * Export `imageFragment`.
 */

export const imageFragment = graphql`
  fragment ResponsiveImage on ImageSharp {
    fluid(
      maxWidth: 1920
      quality: 95
      srcSetBreakpoints: [375, 480, 576, 768, 992, 1200, 1440, 1920]
    ) {
      ...GatsbyImageSharpFluid_withWebp_noBase64
    }
  }
`;

/**
 * Background styles.
 */

const backgroundStyles = {
  bottom: 0,
  left: 0,
  position: 'absolute',
  right: 0,
  top: 0
};

/**
 * `Image` component.
 */

export const Image = GatsbyBackgroundImage;

/**
 * `BackgroundImage` component.
 */

export const BackgroundImage = ({ style, ...rest }: Object): Node => (
  <GatsbyBackgroundImage
    {...rest}
    style={{ ...backgroundStyles, ...style }}
  />
);
