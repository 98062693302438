// @flow

/**
 * Module dependencies.
 */

import { Icon } from 'react-components/media';
import { color, columnWidth, media } from 'react-components/styles';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { graphql, useStaticQuery } from 'gatsby';
import { ifNotProp, ifProp, theme } from 'styled-tools';
import Link, { type ColorTheme } from 'components/core/links/link';
import MenuButton from 'components/core/menu-button';
import React, { type Node, useCallback, useMemo, useState } from 'react';
import logoSvg from 'assets/svg/logo-type.svg';
import styled, { css } from 'styled-components';
import useBreakpoint from 'hooks/use-breakpoint';

/**
 * `Props` type.
 */

type Props = {|
  colorTheme?: ColorTheme
|};

/**
 * Navbar query.
 */

const navbarQuery = graphql`
  query {
    links: allSeegnoCmsNavbarMenuEntity {
      nodes {
        id
        content {
          bold
          label
          url
        }
      }
    }
  }
`;

/**
 * `Nav` styled component.
 */

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  left: 0;
  padding: 0.75rem 0.5rem 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;

  ${ifNotProp('isMenuOpen', css`
    overflow: hidden;
  `)}

  ${media.min('ms')`
    padding: 1.5rem 2rem 0;
  `}
`;

/**
 * `StyledLink` styled component.
 */

const StyledLink = styled(Link)`
  display: inline-flex;
  font-weight: ${ifProp('bold', 500, 400)};

  ${media.max('ms')`
    ${theme('typography.styles.h4')}

    text-align: center;

    ${ifProp('label', css`
      &::after {
        content: attr(aria-label);
        display: inline-block;
        margin-left: 1rem;
      }

      svg {
        max-width: 1.2em;
        width: 1.2em;
      }
    `)}
  `}
`;

/**
 * `LogoLink` styled component.
 */

const LogoLink = styled(Link)`
  position: relative;
  z-index: 1;
`;

/**
 * `MenuListWrapper` styled component.
 */

const MenuListWrapper = styled.div`
  align-items: center;
  display: flex;

  ${media.max('ms')`
    opacity: ${ifProp('isVisible', 1, 0)};
    pointer-events: ${ifProp('isVisible', 'all', 'none')};
    transition: opacity ${theme('transitions.default')};

    &::before {
      background: ${color.transparentize('blue700', 0.65)};
      content: '';
      height: 100vh;
      left: 0;
      position: absolute;
      top: 0;
      width: 100vw;
    }
  `}
`;

/**
 * `MenuList` styled component.
 */

const MenuList = styled.ul`
  display: grid;
  grid-auto-flow: row;
  grid-gap: 2rem;

  ${media.max('ms')`
    background-color: ${color.transparentize('blue800', 0.98)};
    left: 0;
    padding: 8rem ${columnWidth(0.75)}% 4rem;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateY(${ifProp('isVisible', 0, '-100%')});
    transition: transform ${theme('transitions.fast')};
  `};

  ${media.min('ms')`
    grid-auto-flow: column;
  `}
`;

/**
 * `ListItem` styled component.
 */

const ListItem = styled.li`
  align-items: center;
  display: inline-flex;

  ${media.max('ms')`
    justify-content: center;
    text-align: center;
  `}
`;

/**
 * `StyledMenuButton` styled component.
 */

const StyledMenuButton = styled(MenuButton)`
  position: absolute;
  right: 0.5rem;
  top: 0.5rem;
  z-index: 1;

  ${media.min('ms')`
    display: none;
  `}
`;

/**
 * `Navbar` component.
 */

function Navbar({ colorTheme = 'primary' }: Props): Node {
  const data = useStaticQuery(navbarQuery);
  const isMobile = useBreakpoint('ms', 'max');
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navbarLinks = useMemo(() => {
    return data.links.nodes.map(({ content, id }) => ({ ...content, id }));
  }, [data.links.nodes]);

  const handleToggleMenu = useCallback(() => {
    if (isMenuOpen) {
      enableBodyScroll(document.body);
    } else {
      disableBodyScroll(document.body);
    }

    setMenuOpen(isOpen => !isOpen);
  }, [isMenuOpen]);

  return (
    <Nav isMenuOpen={isMenuOpen}>
      <LogoLink href={'/'}>
        <Icon
          icon={logoSvg}
          size={'126px'}
        />
      </LogoLink>

      <StyledMenuButton
        isOpen={isMenuOpen}
        onClick={handleToggleMenu}
      />

      <MenuListWrapper isVisible={isMenuOpen}>
        <MenuList isVisible={isMenuOpen}>
          {navbarLinks.map(({ bold, id, label, url }) => (
            <ListItem key={id}>
              <StyledLink
                bold={bold}
                colorTheme={isMobile ? 'primary' : colorTheme}
                href={url}
                rel={'noopener'}
                target={'_blank'}
              >
                {label}
              </StyledLink>
            </ListItem>
          ))}
        </MenuList>
      </MenuListWrapper>
    </Nav>
  );
}

/**
 * Export `Navbar` component.
 */

export default Navbar;
