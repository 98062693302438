
// @flow

/**
 * Module dependencies.
 */

import { color, globalStyles } from 'react-components/styles';
import { createGlobalStyle } from 'styled-components';
import { theme } from 'styled-tools';

/**
 * Export `GlobalStyles` component.
 */

export const GlobalStyles = createGlobalStyle`
  ${globalStyles}

  p {
    margin: 0;
  }

  body {
    ${theme('typography.styles.p')}

    color: ${color('blue800')};
  }

  svg {
    display: block;
  }
`;
