// @flow

/**
 * Module dependencies.
 */

import { Fill } from 'react-components/layout';
import { Form } from 'components/core/forms/form';
import {
  JoinWaitlistInput
} from 'components/core/forms/fields/join-waitlist-input';

import { Type } from 'react-components/typography';
import { color, units } from 'react-components/styles';
import { graphql, useStaticQuery } from 'gatsby';
import { ifProp, theme } from 'styled-tools';
import { omit } from 'lodash';
import { regexes } from 'utils/regexes';
import { useRecaptcha } from 'hooks/use-recaptcha';
import Checkbox from 'components/core/forms/fields/checkbox';
import RawHtml from 'react-components/raw-html';
import React, { type Node, useCallback, useState } from 'react';
import axios from 'axios';
import styled, { css } from 'styled-components';

/**
 * Newsletter query.
 */

const newsletterQuery = graphql`
  query {
    footer: seegnoCmsFooterEntityEntity {
      content {
        newsletter {
          buttonLabel
          checkboxLabel
          inputLabel
          note
          successMessage
          title
        }
        title
        trademark
      }
    }

    newsletterSubscribeUrl: seegnoCmsSetting(
      code: { eq: "newsletterSubscribeUrl" }
    ) {
      value
    }

    site {
      siteMetadata {
        recaptchaSiteKey
      }
    }
  }
`;

/**
 * Json schema.
 */

const jsonSchema = {
  properties: {
    acceptedTerms: {
      const: true,
      type: 'boolean'
    },
    email: {
      pattern: regexes.email,
      type: 'string'
    }
  },
  required: ['acceptedTerms', 'email']
};

/**
 * `Wrapper` styled component.
 */

const Wrapper = styled.div`
  margin: 0 auto ${units(13)};
  max-width: 720px;
  position: relative;
`;

/**
 * `StyledForm` styled component.
 */

const StyledForm = styled(Form)`
  align-items: center;
  display: flex;
  flex-direction: column;
  transition: opacity ${theme('transitions.fast')};

  ${ifProp('isComplete', css`
    opacity: 0;
  `)}
`;

/**
 * `SuccessMessageWrapper` styled component.
 */

const SuccessMessageWrapper = styled(Fill)`
  align-items: center;
  animation: ${theme('keyframes.fadeIn')} ${theme('transitions.default')};
  display: flex;
  justify-content: center;
`;

/**
 * `SubscriptionForm` component.
 */

function SubscriptionForm(): Node {
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const data = useStaticQuery(newsletterQuery);
  const newsletterSubscribeUrl = data?.newsletterSubscribeUrl?.value;
  const recaptchaSiteKey = data?.site?.siteMetadata?.recaptchaSiteKey;
  const {
    buttonLabel,
    checkboxLabel,
    inputLabel,
    note,
    successMessage,
    title
  } = data?.footer?.content?.newsletter ?? {};

  const handleSubmit = useCallback((values, { reset }) => {
    if (!newsletterSubscribeUrl) {
      return;
    }

    return axios
      .post(newsletterSubscribeUrl, omit(values, 'acceptedTerms'), {
        'Content-Type': 'application/json'
      })
      .then(
        () => {
          reset();
          setComplete(true);
        },
        () => {
          // Ignore errors.
        }
      );
  }, [newsletterSubscribeUrl]);

  const { renderRecaptcha, submitWithRecaptcha } = useRecaptcha({
    onSubmit: handleSubmit,
    recaptchaSiteKey
  });

  if (!newsletterSubscribeUrl) {
    return null;
  }

  return (
    <Wrapper>
      <Type.H2
        as={'div'}
        color={color('white')}
        marginBottom={units(4)}
        textAlign={'center'}
      >
        <RawHtml>
          {title}
        </RawHtml>
      </Type.H2>

      <StyledForm
        aria-hidden={isComplete}
        isComplete={isComplete}
        jsonSchema={jsonSchema}
        onSubmit={submitWithRecaptcha}
      >
        <JoinWaitlistInput
          buttonLabel={buttonLabel}
          buttonType={'submit'}
          label={inputLabel}
          name={'email'}
          onFocus={() => setShowRecaptcha(true)}
          type={'email'}
        />

        <Checkbox
          label={checkboxLabel}
          name={'acceptedTerms'}
        />

        <Type.Label
          as={'div'}
          color={color('grey500')}
          opacity={0.6}
          paddingTop={units(1)}
        >
          <RawHtml>
            {note}
          </RawHtml>
        </Type.Label>

        {showRecaptcha && renderRecaptcha()}
      </StyledForm>

      {isComplete && (
        <SuccessMessageWrapper>
          <Type.H4
            as={'p'}
            textAlign={'center'}
          >
            <RawHtml>
              {successMessage}
            </RawHtml>
          </Type.H4>
        </SuccessMessageWrapper>
      )}
    </Wrapper>
  );
}

/**
 * Export `SubscriptionForm` component.
 */

export default SubscriptionForm;
