// @flow

/**
 * Module dependencies.
 */

import { color, states } from 'react-components/styles';
import { getLinkProps } from 'utils/get-link-props';
import { ifProp, switchProp, theme } from 'styled-tools';
import styled, { css } from 'styled-components';

/**
 * Export `ColorTheme` type.
 */

export type ColorTheme = 'blue' | 'primary' | 'secondary' | 'white';

/**
 * `Link` styled component.
 */

const Link = styled.a.attrs(({ colorTheme, ...rest }) => ({
  ...getLinkProps(rest),
  colorTheme: colorTheme || 'white'
}))`
  color: inherit;
  text-decoration: none;
  transition: ${theme('transitions.default')};
  transition-property: color, opacity;

  ${ifProp('disabled', css`
    cursor: default;
    opacity: 0.5;
    pointer-events: none;
  `)}

  ${switchProp('colorTheme', {
    blue: css`
      &,
      &:any-link {
        color: ${color('blue500')};
      }

      ${states.interaction`
        color: ${color('green300')};
      `}
    `,
    grey: css`
      &,
      &:any-link {
        color: ${color('grey500')};
      }

      ${states.interaction`
        color: ${color('green500')};
      `}
    `,
    primary: css`
      &,
      &:any-link {
        color: ${color('green500')};
      }

      ${states.interaction`
        color: ${color('white')};
      `}
    `,
    secondary: css`
      &,
      &:any-link {
        color: ${color('blue800')};
      }

      ${states.interaction`
        color: ${color('green500')};
      `}
    `,
    white: css`
      &,
      &:any-link {
        color: ${color('white')};
      }

      ${states.interaction`
        color: ${color('green300')};
      `}
    `
  })}
`;

/**
 * Export `Link` component.
 */

export default Link;
