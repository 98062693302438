// @flow

/**
 * Module dependencies.
 */

import { color } from 'react-components/styles';
import styled from 'styled-components';

/**
 * `Separator` styled component.
 */

const Separator = styled.div`
  background-color: ${color.transparentize('white', 0.05)};
  height: 1px;
`;

/**
 * Export `Separator` component.
 */

export default Separator;
