// @flow

/**
 * Module dependencies.
 */

import { capitalize, compact, find } from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { useMemo } from 'react';

/**
 * Ordered social networks.
 */

const orderedSocialNetworks = [
  'telegramUrl',
  'twitterUrl',
  'mediumUrl',
  'linkedinUrl'
];

/**
 * `SocialNetworkKey` type.
 */

type SocialNetworkKey = 'discord' | 'linkedin' | 'medium' | 'twitter';

/**
 * `SocialNetwork` type.
 */

type SocialNetwork = {|
  iconKey: SocialNetworkKey,
  label: string,
  url: string
|};

/**
 * Social Networks query.
 */

const socialNetworksQuery = graphql`
  query {
    settings: allSeegnoCmsSetting {
      nodes {
        code
        description
        value
      }
    }
  }
`;

/**
 * Export `useSocialNetworks` hook.
 */

export function useSocialNetworks(): Array<SocialNetwork> {
  const cmsData = useStaticQuery(socialNetworksQuery);
  const socialNetworks = useMemo(() => {
    return compact(orderedSocialNetworks.map(socialNetwork => {
      return find(cmsData.settings.nodes, ({ code }) => code === socialNetwork);
    }));
  }, [cmsData]);

  return socialNetworks.map(({ code, value }) => {
    let networkKey = code.replace('Url', '');

    // Remap telegram to discord for the time being.
    // TODO: Replace the `telegramUrl` setting with `discordUrl`, or create a
    // social network entity.
    if (networkKey === 'telegram') {
      networkKey = 'discord';
    }

    return {
      iconKey: networkKey,
      label: capitalize(networkKey),
      url: value
    };
  });
}
